<template>
  <div>
    <a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{ paddingTop: 0 }"
      style="max-width: 550px">

      <!-- Form register -->
      <div class="card card-container">
        <img id="profile-img" src="images/logo-laopt.png" class="profile-img-card" />
        <a-form :form="form" @submit="handleResetPassword" class="form-signup">
          <div v-if="!successful">
            <div v-if="current === 0">
              <div class="choice">
                <a-radio-group v-model="value" @change="onChange">
                  <a-radio :value="1">
                    <strong> {{ $t("form.phone") }}</strong>
                  </a-radio>
                  <a-radio :value="2">
                    <strong>{{ $t("form.email") }}</strong>
                  </a-radio>
                </a-radio-group>
              </div>
              <a-row :gutter="24">
                <a-col>
                  <div v-if="showPhone">
                    <div class="my-10">
                      <label for="phone" class="mb-10 mt-20"><strong>{{ $t("form.phone") }}</strong></label>
                      <vue-tel-input class="mt-10" v-model="vueTel.phone" v-bind="vueTel.props"
                        v-validate="'required|min:8|max:30'" type="text" data-vv-rules="required" data-vv-as="Phone"
                        data-vv-scope="formPhone" required name="phone">
                      </vue-tel-input>
                      <div style="color: red">
                        <span v-if="
                          errors.first('formPhone.phone') ==
                          'The Phone field is required.'
                        ">{{ $t("rules.requirePhone") }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="my-10">
                      <label for="email" class="mb-10"><strong> {{ $t("form.email") }}</strong></label>
                      <a-input id="email" v-model="user.email" v-validate="'required|email|max:50'" type="email"
                        class="mt-10" data-vv-rules="required" data-vv-as="Email" data-vv-scope="formEmail" required
                        name="email" />
                      <div style="color: red">
                        <span v-if="
                          errors.first('formEmail.email') ==
                          'The Email field is required.'
                        ">{{ $t("rules.enterEmail") }}</span>
                        <span v-else-if="
                          errors.first('formEmail.email') ==
                          'The Email field must be a valid email.'
                        ">{{ $t("rules.emailForm") }}</span>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <a-row type="flex" justify="start" v-if="current === 1">
              <a-col class="m-10">
                <h6>{{ $t("form.otp") }}</h6>
              </a-col>
              <a-col>
                <OtpInput :isValid="isCodeValid" :digits="4" @on-complete="onCompleteHandler">
                  <template #errorMessage> </template>
                </OtpInput>
              </a-col>
            </a-row>
            <div v-if="current === 2">
              <a-row :gutter="24">
                <a-col :span="24" :sm="24" :md="12">
                  <a-form-item :label="$t('form.password')" has-feedback>
                    <a-input v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('rules.enterPassword'),
                          },
                          {
                            min: 6,
                            message: $t('rules.passwordLength')
                          }
                        ],
                      },
                    ]" type="password" />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="24" :md="12">
                  <a-form-item :label="$t('form.confirmPassword')" has-feedback>
                    <a-input v-decorator="[
                      'confirm',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('rules.enterPassword'),
                          },
                          {
                            validator: compareToFirstPassword,
                          },
                        ],
                      },
                    ]" type="password" @blur="handleConfirmBlur" />
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-form>

        <div class="steps-action">
          <a-button v-if="current > 0" style="margin: 5px 0 0 30px" @click="prev">
            {{ $t("button.previous") }}
          </a-button>
          <a-button v-if="current < steps.length - 1" type="primary" style="margin: 5px 0 20px 30px" @click="next">
            {{ $t("button.next") }}
          </a-button>
          <a-button v-if="current == steps.length - 1" type="primary" style="margin: 10px" @click="handleResetPassword">
            {{ $t("button.submit") }}
          </a-button>
        </div>
        <div v-if="message" style="color: red">
          {{ message }}
        </div>
      </div>

      <p class="font-semibold text-muted text-center">
        {{ $t("form.dontHaveAccount") }}?
        <router-link to="/sign-up" class="font-bold text-dark">{{
          $t("button.register")
        }}</router-link>
      </p>
    </a-card>
  </div>
</template>

<script>
import User from "../../../models/user.model";
import OtpInput from "otp-input-vue2";
import UserService from "../../../services/user.service";
import { message } from "ant-design-vue";
import { hashPassword } from "../../../utils/helper";
import ForgetPasswordService from "../../../services/forgetPassword.service";

export default {
  name: "CardForget",
  components: {
    OtpInput,
  },

  data() {
    return {
      user: new User,
      submitted: false,
      successful: false,
      message: "",
      value: 1,
      isCodeValid: false,
      showPhone: true,
      vueTel: {
        phone: "",
        props: {
          preferredCountries: ["LA", "CN", "TH", "VN"],
          mode: "international",
          required: true,
          validCharactersOnly: true,
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: false,
          wrapperClasses: "country-phone-input",
        },
      },

      current: 0,
      steps: [
        {
          title: "Reset option",
          content: "",
        },
        {
          title: "Get OTP",
          content: "",
        },
        {
          title: "Fill Form",
          content: "",
        },
      ],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'reset' });
  },
  methods: {
    onChange() {
      if (this.value == 1) {
        this.showPhone = true;
      } else {
        this.showPhone = false;
      }
    },
    next() {
      if (this.current === 0) {
        if (this.value === 1) {
          this.$validator.validateAll("formPhone").then((isValid) => {
            if (isValid) {
              this.user.type = 'phone'
              this.user.phone = this.vueTel.phone.replace(/\s+/g, '')
              ForgetPasswordService.requestOTP(this.user).then(res => {
                this.current++;
              }).catch((err) => {
                message.error(err.response.data.message);
              })
            }
            else {
              return this.$message.error(this.$t('rules.requirePhone'))
            }
          });
        } else {
          this.$validator.validateAll("formEmail").then((isValid) => {
            if (isValid) {
              this.user.type = 'email'
              ForgetPasswordService.requestOTP(this.user).then(res => {
                this.current++;
              }).catch((err) => {
                console.log(err)
                message.error(err.response.data.message);
              })

            } else {
              return this.$message.error(this.$t('rules.emailForm'))
            }
          });
        }
      } else if (this.current === 1) {
        if (this.isCodeValid === true) {
          ForgetPasswordService.confirmOTP(this.user).then(res => {
            this.current++;
            this.isCodeValid = false;
          }).catch((err) => {
            this.$message.error(err.response.data.message)
          })
        } else {
          this.isCodeValid = false;
          this.$message.error("Please fill the valid OTP!")
        }
      }
    },
    prev() {
      this.current--;
    },
    onCompleteHandler(code) {
      if (code.length === 4) {
        this.user.otp = code
        this.isCodeValid = true;
      }
      else {
        this.isCodeValid = false;
      }

    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('The two passwords not match!');
      } else {
        callback();
      }
    },
    handleResetPassword() {
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const user = {
            ...values,
            email: this.user.email,
            phone: this.user.phone,
            password: hashPassword(values.password),
            type: this.user.type
          };
          ForgetPasswordService.confirmReset(user).then(res => {
            this.message = res.message;
            this.successful = true;
            this.$router.push("/");
            this.$message.success("Reset password successful!");
          }).catch((err) => {
            this.$message.error(err.response.data.message)
          })
          // this.$store.dispatch("auth/register", user).then(
          //   (data) => {
          //     this.message = data.message;
          //     this.successful = true;
          //     this.$router.push("/");
          //     this.$message.success("Register successful!");
          //   },

          //   (error) => {
          //     this.message =
          //       (error.response &&
          //         error.response.data &&
          //         error.response.data.message) ||
          //       error.message ||
          //       error.toString();
          //     this.successful = false;
          //     this.$message.error("Register failed");
          //   }
          // );
        }
      });
    }

  },
};
</script>
<style scoped lang="css">
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 10px auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-signup {
  margin: 30px;
}

.choice {
  font-size: 30px;
  /* margin: 20px 0 0 0; */
  padding: 3px;
  background: #91D29A;
  border-radius: 5px;
  max-width: auto;
  text-align: center;
}

.card {
  max-width: 600px;
}
</style>
