import {baseUrl} from '../utils/helper';
import axios from './axios-interceptor';

class ForgetPasswordService {
  requestOTP(data){
    return axios.post(`${baseUrl}/forgotpassword/request`,data)
  }
  confirmOTP(data){
    return axios.post(`${baseUrl}/forgotpassword/confirm`,data)
  }
  confirmReset(data){
    return axios.post(`${baseUrl}/forgotpassword/update`,data)
  }
}

export default new ForgetPasswordService();
