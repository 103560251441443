<template>
  <div>
    <div
      class="sign-up-header"
      style="background-image: url('images/background.jpeg')"
    >
      <div class="content">
        <h3 class="mb-5">{{ $t("forgetPass.resetPass") }}</h3>
      </div>
    </div>
    <CardForget />
  </div>
</template>
<script>
import CardForget from "./components/CardForget.vue";
export default {
  components: {
    CardForget,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style></style>
